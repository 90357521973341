import '@babel/polyfill'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import Vue from 'vue'
import App from '@/components/App.vue'
import router from './routes'

import LazyTube from "vue-lazytube"
Vue.use(LazyTube)

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

import './vee-validations'
import VueMq from 'vue-mq'

import { SET_FROM } from './store/mutations'
import { RESET } from './store/actions'

import * as Cookies from 'js-cookie'
import 'array-flat-polyfill'

import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './icons'
import ActionCableVue from 'actioncable-vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import LiveHelpNowBuilder from './live-help-now'
window.LiveHelpNowBuilder = LiveHelpNowBuilder

Vue.component('fa-icon', FontAwesomeIcon)

window._ = require('lodash')

//*Globals
global.DateTime
global.uuidv1 = require('uuid/v1')
global.cookies = Cookies
global._ = require('lodash')
global.debug = process.env.NODE_ENV !== 'production'

global.routesIgnoringAuth = [
  '/viewer',
  '/reset',
  '/login',
  '/guest-pay',
  '/forgot-password',
  '/free-account-signup',
  '/free-account-additional-details',
]

global.capturePathAndClearState = function(unauthorized) {
  if (
    global.routesIgnoringAuth.filter(route => router.history.current.path.search(route)).length === 0
    && !unauthorized
  ) {
    store.commit(SET_FROM, { from: router.history.current.path })
    store.dispatch(RESET, null, { root: true })
    router.push('/login')
  }
}
window.router = router

Vue.config.productionTip = false

Vue.use(IconsPlugin)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  // See documentation for optional default values (https://vcalendar.io/api/defaults.html)
})

import VueCardFormat from 'vue-credit-card-validation/src'
Vue.use(VueCardFormat)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol : '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
})

Vue.use(VueMq, {
  breakpoints: {
    sm: 736,
    md: 1158,
    lg: Infinity,
  },
})

// if (process.env.NODE_ENV === 'development') {
//   Cookies.set('admin', 'logged_in_as_client')
//   sessionStorage.setItem('admin-logged-in', 'true')
//
//   const v3Mock = require('./api/v3/mock/worker')
//   v3Mock.worker.start({
//     onUnhandledRequest: ({ url }, print) => {
//       if (url.pathname.startsWith('/api/v3')) {
//         print.warning()
//       }
//     },
//   })
// }

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: false,
})

import VueMatomo from 'vue-matomo'
Vue.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_HOST_URL,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router,
})

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')

// Matomo - Client Tracking
window._paq.push(['trackPageView'])

// Initialize Data Map Package
import { dataMapPackageInit } from './data-map-package-helper'
dataMapPackageInit({ Vue, store })
