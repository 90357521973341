import mediaTypes from '@/lib/mediaTypes'
import * as t from '@/store/mutations'
const { jsonApi } = mediaTypes

const reseller_id = '8d5e2db7-5a19-40b1-a247-ab281071b4fd'

const initialState = () => ({
  stages: [],
  stage: {},
  steps: [],
  accountsStage: {},
  slide: {},
})

const STATE = initialState()

const GETTERS = {
  stages: state => state.stages,
  stage: state => state.stage,
  steps: state => state.steps,
  accountsStage: state => state.accountsStage,
  slide: state => state.slide,
  isFirstSlide: state => state.slide.position === 0 && state.steps.find(s => s.id === state.slide.step.id)?.position === 0,
  isLastSlide: state => state.slide.position === state.steps[state.steps.length - 1].slides.length - 1 &&
    state.steps.find(s => s.id === state.slide.step.id)?.position === state.steps.length - 1,
  requestConfig: (_state, _getters, _rootState, rootGetters) => ({
    baseURL: process.env.VUE_APP_STAGE_MANAGER_URL,
    headers: {
      Authorization: `Bearer ${rootGetters['session/getToken']}`,
    },
  }),
  defaultParams: (_state, _getters, _rootState, rootGetters) => ({
    account_id: rootGetters['account/actualAccountID'],
    reseller_id,
  }),
}

// TODO: Should be able to dynamically populate state based on JSON:API responses.
const ACTIONS = {
  // GET requests
  async getStages({ commit, getters }, { clear = true, company_id = null, title = null, ...jsonApiConfig }) {
    const response = await jsonApi.get('/api/v1/stageline/stages', getters.requestConfig, {
      ...jsonApiConfig,
      ...(company_id != null && { company_id }),
      ...(title != null && { title }),
      ...getters.defaultParams,
    })
    const stages = clear ? response : [...getters.stages, ...response]
    commit(t.SET_STAGES, stages)
  },
  async getStage({ commit, getters }, { id, company_id = null, ...jsonApiConfig }) {
    commit(t.SET_STAGE, await jsonApi.get(`/api/v1/stageline/stages/${id}`, getters.requestConfig, {
      ...jsonApiConfig,
      ...(company_id != null && { company_id }),
      ...getters.defaultParams,
    }))
  },
  async getSteps({ commit, getters }, { stageId = null, ...jsonApiConfig }) {
    // TODO: Works for now, but need to update deserializer to handle collections and links.
    const url = stageId ? `/api/v1/stageline/stages/${stageId}/steps` : getters.stage.steps[0]._link
    commit(t.SET_STEPS, await jsonApi.get(url, getters.requestConfig, jsonApiConfig))
  },
  async getAccountsStage({ commit, getters }, { id = null, ...jsonApiConfig }) {
    const url = id ? `/api/v1/stageline/accounts_stages/${id}` : getters.stage.accounts_stage._link
    commit(t.SET_ACCOUNTS_STAGE, await jsonApi.get(url, getters.requestConfig, jsonApiConfig))
  },
  async getSlide({ commit, getters }, { id = null, ...jsonApiConfig }) {
    const url = id ? `/api/v1/stageline/slides/${id}` : getters.accountsStage.current_slide._link
    commit(t.SET_SLIDE, await jsonApi.get(url, getters.requestConfig, jsonApiConfig))
  },

  // POST requests
  async createTransition({ getters }, { data = {}, company_id = null }) {
    await jsonApi.post('/api/v1/stageline/transitions', getters.requestConfig, {
      data,
      type: 'transition',
      ...getters.defaultParams,
      ...(company_id != null && { company_id }),
    })
  },
}

const MUTATIONS = {
  [t.SET_STAGES](state, stages) {
    state.stages = stages
  },
  [t.SET_STAGE](state, stage) {
    state.stage = stage
  },
  [t.SET_STEPS](state, steps) {
    state.steps = steps
  },
  [t.SET_ACCOUNTS_STAGE](state, accountsStage) {
    state.accountsStage = accountsStage
  },
  [t.SET_SLIDE](state, slide) {
    state.slide = slide
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
