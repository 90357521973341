// Client
export const CLIENT = `client`
export const CLIENT_ACCOUNT = `${CLIENT}/account`
export const CLIENT_AUTH = `${CLIENT}/auth`
export const CLIENT_COMPANIES = `${CLIENT}/companies`
export const CLIENT_COMPANIES_AGENCY_RESOURCES = `${CLIENT}/companies_agency_resources`
export const CLIENT_CONNECTION_INFO = `${CLIENT}/connection_info`
export const CLIENT_CREDIT_CARD_PROCESSING = `${CLIENT}/credit_card_processing`
export const CLIENT_DOMAINS = `${CLIENT}/domains`
export const CLIENT_EPHEMERAL_MESSAGES = `${CLIENT}/ephemeral_messages`
export const CLIENT_ENTITY_TYPES = `${CLIENT}/entity_types`
export const CLIENT_INVITATIONS = `${CLIENT}/invitations`
export const CLIENT_ORDER_ITEMS = `${CLIENT}/order_items`
export const CLIENT_ORDER_DASH_ITEMS = `${CLIENT}/order_items`
export const CLIENT_PRODUCTS = `${CLIENT}/products`
export const CLIENT_SERVICES = `${CLIENT}/services`
export const CLIENT_SUBSCRIPTIONS = `${CLIENT}/subscriptions`
export const CLIENT_STAGELINE = `${CLIENT}/stageline`
export const CLIENT_TRIAL_ELIGIBILITY = `${CLIENT}/trial_eligibility`
export const CLIENT_VIRTUAL_PHONES = `${CLIENT}/virtual_phones`
export const CLIENT_WEBSITES = `${CLIENT}/websites`

// Client Account
export const CLIENT_ACCOUNT_SUMMARY = `${CLIENT_ACCOUNT}/summary`
export const CLIENT_ACCOUNT_COMPANY_REQUIRING_STAGELINE_REDIRECT = `${CLIENT_ACCOUNT}/company_requiring_stageline_redirect`

// Client Auth
export const CLIENT_AUTH_LOGIN = `${CLIENT_AUTH}/login`
export const CLIENT_AUTH_FORGOT_PASSWORD = `${CLIENT_AUTH}/forgot-password`
export const CLIENT_AUTH_REFRESH = `${CLIENT_AUTH}/refresh`

// Client Companies
export const CLIENT_COMPANIES_INDEX_SIMPLE = `${CLIENT_COMPANIES}/index_simple`
export const CLIENT_COMPANIES_SHARED_SIMPLE = `${CLIENT_COMPANIES}/shared_simple`

// Client Credit Card Processing
export const CLIENT_CREDIT_CARD_PROCESSING_COMPANY_ELIGIBLE_TO_ORDER_MERCHANT_SERVICE = `${CLIENT_CREDIT_CARD_PROCESSING}/company_eligible_to_order_merchant_service`
export const CLIENT_CREDIT_CARD_PROCESSING_PROCESSING_AD_STATUS = `${CLIENT_CREDIT_CARD_PROCESSING}/processing_ad_status`

// Client Interaction Log
export const CLIENT_INTERACTION_LOG = `${CLIENT}/client_interaction_log`
export const CLIENT_INTERACTION_LOG_CREATE_OR_FIND_INTERACTION = `${CLIENT_INTERACTION_LOG}/create_or_find_interaction`

// Client Invitations
export const CLIENT_INVITATIONS_RECEIVED_COUNT = `${CLIENT_INVITATIONS}/received_count`

// Client Order Items
export const CLIENT_ORDER_ITEMS_ORDER_VERIFICATION_REQUIRED = `${CLIENT_ORDER_ITEMS}/order_verification_required`

// Client Products
export const CLIENT_PRODUCTS_AVAILABLE_UPSELLS = `${CLIENT_PRODUCTS}/available_upsells`
export const CLIENT_PRODUCTS_OPTIONAL_CATEGORIES = `${CLIENT_PRODUCTS}/optional_categories`
export const CLIENT_PRODUCTS_CATEGORIES = `${CLIENT_PRODUCTS}/categories`
export const CLIENT_PRODUCTS_FILING_PRODUCTS = `${CLIENT_PRODUCTS}/filing_products`
export const CLIENT_PRODUCTS_FILING_PRODUCTS_EIN_FILING_PRODUCT_ORDER_ELIGIBLE = `${CLIENT_PRODUCTS_FILING_PRODUCTS}/ein_filing_product_order_eligible`
export const CLIENT_PRODUCTS_FILING_PRODUCTS_S_CORP_FILING_PRODUCT_ORDER_ELIGIBLE = `${CLIENT_PRODUCTS_FILING_PRODUCTS}/s_corp_filing_product_order_eligible`

export const CLIENT_PRODUCTS_PRODUCT_BUNDLES = `${CLIENT_PRODUCTS}/product_bundles`
export const CLIENT_PRODUCTS_REGISTERED_AGENT_PRODUCTS = `${CLIENT_PRODUCTS}/registered_agent_products`
export const CLIENT_PRODUCTS_SIMPLE_PRODUCTS = `${CLIENT_PRODUCTS}/simple_products`

// Client Virtual Phones
export const CLIENT_VIRTUAL_PHONES_RECEIVED_PHONE_NOTIFICATION_COUNT = `${CLIENT_VIRTUAL_PHONES}/received-phone-notification-count`
export const CLIENT_VIRTUAL_PHONES_PENDING_REMOVAL = `${CLIENT_VIRTUAL_PHONES}/virtual-phones-pending-removal`

// Filings
export const FILINGS = `filings`
export const FILINGS_MAILING_FILING_METHOD = `${FILINGS}/mailing_filing_method`

// Filing Methods
export const FILING_METHODS = `filing_methods`

// Jurisdictions
export const JURISDICTIONS = `jurisdictions`

// TODO: Update references to the consts below this line to the new consts above.
// TODO: Will require prepending '/api/v3/' to the new const where referenced, or updating to use axios client.
export const AUTH_CREATE = '/api/v3/client/auth/login'
export const AUTH_FORGOT_PASSWORD = '/api/v3/client/auth/forgot_password'
export const AUTH_REFRESH = '/api/v3/client/auth/refresh'

export const ACCOUNT = '/api/v3/client/account'
export const ACCOUNT_DASHPANEL = '/api/v3/client/account/summary'
export const ACCOUNT_UNREAD_DOCUMENTS_COUNT = '/api/v3/client/account/unread_documents_count'
export const ACCOUNT_DOCUMENTS = '/api/v3/client/client_documents'
export const ACCOUNT_COMPANIES = '/api/v3/client/companies'
export const ACCOUNT_COMPANY_HOME_JURISDICTION = '/api/v3/client/companies/:companyId/set-domestic-jurisdiction'
export const ACCOUNT_SHARED_COMPANIES = '/api/v3/client/companies/shared'
export const ACCOUNT_NOTIFICATION_PREFERENCES =
  '/api/v3/client/account/account_notification_preferences'

export const ACCOUNT_INVOICES = '/api/v3/client/invoices'
export const ACCOUNT_INVOICE = '/api/v3/client/invoices'
export const ACCOUNT_ORDER_ITEMS = '/api/v3/client/order_items'
export const ACCOUNT_INVOICE_BULK_DOWNLOAD = '/api/v3/client/invoices/bulk_download'
export const ACCOUNT_PAY_INVOICES = '/api/v3/client/invoices/pay'
export const ACCOUNT_GUEST_PAY_INVOICES = '/api/v3/client/invoices/guest_pay'
export const ACCOUNT_GUEST_INVOICE_PAYMENT = '/api/v3/client/invoices/notify_guest_payment'
export const ACCOUNT_SHARED_INVOICES = '/api/v3/client/invoices/shared'
export const ACCOUNT_GUEST_FIND_INVOICE = '/api/v3/client/invoices/guest_find'
export const ACCOUNT_CLIENT_REFUND_REQUEST = '/api/v3/client/invoices/request_invoice_refund'

export const WEBSITE = '/api/v3/client/websites'
export const WEBSITE_KEYCLOAK_CONFIG = '/api/v3/client/websites/keycloak_config'

export const DOCUMENTS_PAGE_DOWNLOAD  = '/api/v3/client/client_documents'
export const DOCUMENTS_BULK_DOWNLOAD  = '/api/v3/client/client_documents/bulk_download'

export const PRODUCTS_BASE = '/api/v3/client/products'

export const ENTITY_SUFFIXES = '/api/v3/client/entity_suffixes'

export const COMPANY_INVITE = '/api/v3/client/companies/:companyId/invite'
export const COMPANY_SERVICES = '/api/v3/client/companies/:id/services'
export const ACCOUNT_ORDER = '/api/v3/client/orders'
export const COMPANY_ORDER = '/api/v3/client/companies/:companyId/orders'
export const COMPANY_PAYMENT = '/api/v3/client/companies/:companyId/set_payment_methods'
export const DRAFTS = '/api/v3/client/drafts'

export const ADD_COMPLIANCE_SERVICE = '/api/v3/client/companies/:companyId/registrations/:registrationId/add_compliance_service'

export const FILING_BY_COMPANY_AND_JURISDICTIONS =
  '/api/v3/client/companies/:companyId/filings_for_jurisdiction'

export const FILING_AND_AGENCY_DATA = '/api/v3/filings/agency_data'

export const CANCEL_SERVICES = '/api/v3/client/services/cancel'
export const SET_AUTOPAY_ID = '/api/v3/client/companies/:company_id/services/:service_id/set_autopay_id'
export const OUTSTANDING_BALANCE_INFO = '/api/v3/client/services/outstanding_balance_info'
export const PAY_OUTSTANDING_BALANCE_AND_CANCEL = '/api/v3/client/services/pay_outstanding_balance_and_cancel'

export const PAGE_ROTATIONS = 'api/v3/client/page_transformations'
