import * as t from '../mutations'
import http from '@/http'
import _ from 'lodash'
import { Client, GET } from '@/api/client'
import {
  ACCOUNT_GUEST_FIND_COMPLIANCE_FILINGS,
} from '@/api/v3/endpoints'

const STATE = {
  jurisdictionFilings: [],
  complianceFilings: [],
  filingsLoaded: false,
  eligibleToOrderEin: false,
  eligibleToOrderSCorp: false,
  eligibleToOrderFincenBoi: false,
  refundableFilings: {},
  requiresFormationFiling: false,
  orderItemsRequiringAttention: [],
}

const GETTERS = {
  jurisdictionFilings: state => state.jurisdictionFilings,
  complianceFilings: state => state.complianceFilings,
  activeFilings: state => _.uniqBy([...state.jurisdictionFilings, ...state.complianceFilings], 'id'),
  filingsLoaded: state => state.filingsLoaded,
  eligibleToOrderEin: state => state.eligibleToOrderEin,
  eligibleToOrderSCorp: state => state.eligibleToOrderSCorp,
  eligibleToOrderFincenBoi: state => state.eligibleToOrderFincenBoi,
  requiresFormationFiling: state => state.requiresFormationFiling,
  refundableFilings: state => state.refundableFilings,
  einFilingOrderEligibility:  (state) => () => {
    return state.eligibleToOrderEin
  },
  sCorpFilingOrderEligibility: (state) => () => {
    return state.eligibleToOrderSCorp
  },
}

const ACTIONS = {
  async loadJurisdictionActiveFilings({ commit }, params) {
    commit(t.LOAD_FILINGS_START)
    let filings = []
    for (const p of params) {
      let res = await http.get('client/order_items/jurisdiction_filings', {
        params: {
          jurisdiction_id: p.jurisdictionId,
          company_id: p.companyId },
      })
      filings = filings.concat(res.data.result)
    }
    commit(t.LOAD_JURISDICTION_FILINGS_FINISHED, filings)
  },

  async loadComplianceActiveFilings({ commit }, params) {
    commit(t.LOAD_FILINGS_START)
    let res = await http.get('client/order_items/compliance_filings', {
      params: {
        service_ids: params,
      },
    })
    commit(t.LOAD_COMPLIANCE_FILINGS_FINISHED, res.data.result)
  },

  async fetchComplianceFilingsGuest({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      const { data } = await client.call({
        method: GET,
        path: ACCOUNT_GUEST_FIND_COMPLIANCE_FILINGS,
        params: params,
      })
      return data
    } catch (error) {
      return error
    }
  },

  async cancelOrderItems({ _commit }, filingIds) {
    await http.post('client/cancellations/cancel_services_and_filings', {
      cancel: {
        service_item_ids: [],
        order_item_ids: filingIds,
        signature: '',
        note: '',
      },
    })
  },

  async setEinEligibilityStatus({ commit }, companyId) {
    let response = await http.get('client/products/filing_products/ein_filing_product_order_eligible', {
      params: {
        company_id: companyId,
      },
    })
    commit(t.SET_EIN_ELIGIBILITY_STATUS, response.data.eligible)
  },

  async setSCorpEligibilityStatus ({ commit }, companyId) {
    let response = await http.get('client/products/filing_products/s_corp_filing_product_order_eligible', {
      params: {
        company_id: companyId,
      },
    })
    commit(t.SET_SCORP_ELIGIBILITY_STATUS, response.data.eligible)
    commit(t.SET_REQUIRES_FORMATION_FILING, response.data.requiresFormationFiling)
  },

  async setFincenBoiEligibilityStatus ({ commit }, companyId) {
    const response = await http.get('client/products/filing_products/fincen_boi_filing_product_order_eligible', {
      params: {
        company_id: companyId,
      },
    })
    commit(t.SET_FINCEN_BOI_ELIGIBILITY_STATUS, response.data.eligible)
  },

  async loadOrderItemsRequiringAttention({ commit }) {
    const response = await http.get(`client/order_items/requiring_attention`)

    commit(t.SET_ORDER_ITEMS_REQUIRING_ATTENTION, response.data.result)
  },
}

const MUTATIONS = {
  [t.LOAD_FILINGS_START](state) {
    state.filingsLoaded = false
  },

  [t.LOAD_JURISDICTION_FILINGS_FINISHED](state, filings) {
    state.jurisdictionFilings = filings
    state.filingsLoaded = true
  },

  [t.LOAD_COMPLIANCE_FILINGS_FINISHED](state, filings) {
    state.complianceFilings = filings
    state.filingsLoaded = true
  },

  [t.SET_EIN_ELIGIBILITY_STATUS](state, eligibleToOrderEin) {
    state.eligibleToOrderEin = eligibleToOrderEin
  },
  [t.SET_SCORP_ELIGIBILITY_STATUS](state, eligibleToOrderSCorp) {
    state.eligibleToOrderSCorp = eligibleToOrderSCorp
   },
  [t.SET_FINCEN_BOI_ELIGIBILITY_STATUS](state, eligibleToOrderFincenBoi) {
    state.eligibleToOrderFincenBoi = eligibleToOrderFincenBoi
  },
  [t.SET_REQUIRES_FORMATION_FILING](state, requiresFormationFiling) {
    state.requiresFormationFiling = requiresFormationFiling
  },
  [t.SET_REFUNDABLE_FILINGS](state, refundableFilings) {
    state.refundableFilings = refundableFilings
  },
  [t.SET_ORDER_ITEMS_REQUIRING_ATTENTION](state, orderItems) {
    state.orderItemsRequiringAttention = orderItems
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
