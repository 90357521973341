import axios from 'axios'

const jsonApiMediaType = 'application/vnd.api+json'

const normalizeUrl = (url) => {
  const decodedURI = decodeURI(url)
  try {
    return new URL(decodedURI)
  } catch {
    return {
      pathname: decodedURI,
    }
  }
}

const jsonApiDeserializer = (response, depth = 0, processed = {}, parentKey = null) => {
  const maxDepth = 10
  if (depth >= maxDepth) {
    return { error: "max depth reached" }
  }

  const { data } = response

  if (Array.isArray(data)) {
    return data.map(item => jsonApiDeserializer({ data: item, included: response.included }, depth, processed))
  }

  const key = `${response.key || data.type}-${data.id}-${parentKey || data.type}`

  // Prevent circular logic
  if (processed.hasOwnProperty(key)) {
    return JSON.parse(JSON.stringify(processed[key]))
  }

  const result = { ...data.attributes, id: data.id, type: data.type }

  processed[key] = JSON.parse(JSON.stringify(result))

  if (Object.keys(data.relationships || {}).length) {
    Object.keys(data.relationships).forEach(key => {
      const { data: relData, links } = data.relationships[key]
      const isArray = Array.isArray(relData)
      if (isArray) {
        result[key] = []
      }
      const dataArray = isArray ? relData : [relData]
      dataArray.forEach((relItem) => {
        if (!relItem) return

        const includedItem = response.included?.find(
          (inc) => inc.id === relItem.id && inc.type === relItem.type
        )

        const item = includedItem ? jsonApiDeserializer({ data: includedItem, included: response.included, key }, depth + 1, processed, response.key) : relItem
        item._loaded = !!includedItem

        if (links?.related) {
          item._link = links.related
        }

        isArray ? result[key].push(item) : result[key] = item
      })
    })
  }

  if (depth === 0) {
    result._json_api = response
  }

  return result
}

// TODO: Add relationships.
const jsonApiSerializer = ({ id = null, type, data = {} }) => {
  return {
    data: {
      type,
      ...(!!id && { id }),
      attributes: data,
    },
  }
}

const jsonApiGetRequest = async (url, config, { limit = null, include = '', fields = {}, page = null, deserialize = true, ...queryParams }) => {
  const urlObj = normalizeUrl(url)
  const searchParams = urlObj.searchParams ? Object.fromEntries(urlObj.searchParams.entries()) : {}
  // TODO: Use the host defined in the url over the config if there is one

  const response = await axios.get(`${urlObj.pathname}.json_api`, {
    ...config,
    params: {
      include,
      fields,
      ...(limit != null && { limit }),
      ...(page != null && { page }),
      ...queryParams,
      ...searchParams, // Takes priority over query params
    },
  })
  return deserialize ? jsonApiDeserializer(response.data) : response.data
}

const jsonApiPostRequest = async (url, config, { data, type = null, serialize = true, deserialize = false, ...queryParams }) => {
  const postData = serialize && type ? jsonApiSerializer({ type, data }) : data
  const urlObj = normalizeUrl(url)
  config['headers'] = {
    ...(config['headers'] || {}),
    'Content-Type': jsonApiMediaType,
    'Accept': jsonApiMediaType,
  }

  const response = await axios.post(`${urlObj.pathname}.json_api`, postData, {
    ...config,
    params: queryParams,
  })

  // By default, don't attempt to deserialize the response object. This is dependent on the endpoint
  return deserialize ? jsonApiDeserializer(response.data) : response.data
}

export default {
  jsonApi: {
    deserialize: jsonApiDeserializer,
    serialize: jsonApiSerializer,
    get: jsonApiGetRequest,
    post: jsonApiPostRequest,
  },
}
