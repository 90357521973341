import * as t from '../mutations'
import http from '@/http'
import axiosClient from '@/http'

import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'

import { SERVICE_TYPES, OPERATING_STATUSES } from '@/common/modules/constants'

const STATE = {
  ...BaseState,
  namespace: 'account',
  actualAccountID: null,
  account: null,
  currentContact: {},
  emails: null,
  phones: null,
  addresses: [],
  generalInfo: null,
  people: [],
  primaryEmailCount: null,
  primaryEmailExists: null,
  primaryEmailOnlyOne: null,
  missingContactInformation: null,
  websiteId: null,
  type: null,
  knownBadAddresses: null,
  username: null,
  doesUsernameExit: null,
  usernameToken: null,
  isNotAccountOwner: false,
  servicesRequiringAttentionCount: 0,
  activeServicesCount: 0,
  redirectLoaded: false,
  stagelineRedirected: false,
  redirect: null,
  companyRequiringStagelineRedirect: null,
  activeAccountServices: [],
}

const SERVICE_TYPES_VALUES = Object.values(SERVICE_TYPES)
const OPERATING_STATUSES_VALUES = Object.values(OPERATING_STATUSES)

const GETTERS = {
  ...BaseGetters,
  account: state => state.account,
  actualAccountID: state => state.actualAccountID,
  currentContact: state => state.currentContact,
  people: state => state.people,
  peopleWithAllRequiredInfo: state => state.people.filter(p => p.person_addresses.length && p.person_phones.length && p.person_emails.length),
  allRequiredInfoPersonByName: (state, getters) => (first_name, last_name) => {
    return getters.peopleWithAllRequiredInfo.find(p => (p.first_name === first_name && p.last_name === last_name))
  },
  servicesRequiringAttentionCount: state => state.servicesRequiringAttentionCount,
  phones: state => state.phones,
  // eslint-disable-next-line no-unused-vars
  phoneFor: (state) => person => {
    const potentialPrimaryPhone = person.person_phones.find(pp => pp.kind === 'primary')

    if (potentialPrimaryPhone) {
      return potentialPrimaryPhone.phone_number
    } else {
      return person.person_phones?.[0] ? person.person_phones[0].phone_number : null
    }
  },
  addresses: state => state.addresses,
  emails: state => state.emails,
  // eslint-disable-next-line no-unused-vars
  emailFor: (state) => person => {
    const potentialPrimaryEmail = person.person_emails.find(pe => pe.kind === 'primary')

    if (potentialPrimaryEmail) {
      return potentialPrimaryEmail.email_address
    } else {
      return person.person_emails?.[0] ? person.person_emails[0].email_address : null
    }
  },
  generalInfo: state => state.generalInfo,
  primaryEmailCount: state => state.emails.filter(o => o.kind === 'primary').length,
  primaryEmailExists: (state, getters) => getters.primaryEmailCount > 0,
  primaryEmailOnlyOne: (state, getters) => getters.primaryEmailCount === 1,
  missingContactInformation: (state) => state.missingContactInformation,
  filteredPeople: (state, getters) => filter => {
    if (filter !== '') {
      const regex = new RegExp(filter, 'i')

      return getters.people.filter(p => (regex.test(p.first_name) || regex.test(p.last_name)))
    } else {
      return getters.people
    }
  },
  websiteId: state => state.websiteId,
  redirect: state => state.redirect,
  isRegularAccount: state => state.type === 'owner',
  knownBadAddresses: state => state.knownBadAddresses && state.knownBadAddresses.filter((address) => {
    return address.is_known_bad_address === true
  }).length,
  username: state => state.username,
  doesUsernameExit: state => state.doesUsernameExit,
  usernameToken: state => state.usernameToken,
  isNotAccountOwner: state => state.isNotAccountOwner,
  accountType: state => state.type,
  isAccountWholesaler: state => state.type === 'wholesale-registered-agent' || state.type === 'tax-firm' || state.type === 'attorney' || state.type === 'corporate-service-company',
  activeServicesCount: state => state.activeServicesCount,
  stagelineRedirectsLoaded: state => state.stagelineRedirectsLoaded,
  stagelineRedirected: state => state.stagelineRedirected,
  companyRequiringStagelineRedirect: state => state.companyRequiringStagelineRedirect,
  activeAccountServices: state => state.activeAccountServices,
  activeServicesByType: (state, getters) => type => {
    return getters.activeAccountServices.filter(service => service.type === type)
  },
}

const ACTIONS = {
  ...BaseActions,
  async loadAccount ({ commit, getters }, preLoadedAccount = null) {
    const res = preLoadedAccount ? {} : await http.get('client/account')
    const account = preLoadedAccount || res.data.result.account
    const owner = account.people?.find(e => e.id === account.owner_id)

    // Sort People and Companies together alphabetically by name
    account.people.sort((a, b) => {
      a.compare_name = a.contact_is_company ? a.contact_company_name : a.first_name + a.last_name
      b.compare_name = b.contact_is_company ? b.contact_company_name : b.first_name + b.last_name
      return a.compare_name.localeCompare(b.compare_name)
    })

    // Place Account Owner first in the array of people
    account.people.unshift(
      account.people.splice(
        account.people.findIndex(person => person.id === owner.id), 1
      )[0]
    )
    const people = account.people
    const currentContact = people?.find(p => p.id === getters.currentContact.id)

    commit(t.SET_ACCOUNT_DETAILS, account)
    commit(t.SET_ALL_PEOPLE, people)

    if (currentContact) {
      commit(t.SET_CURRENT_CONTACT, currentContact)
    }

    if (!owner) {
      commit(t.SET_MISSING_CONTACT_INFORMATION, true)
    } else {
      commit(t.SET_ACTUAL_ACCOUNT_ID, account.id)
      commit(t.SET_OWNER_ACCOUNT, owner)
      commit(t.SET_PHONES, owner.person_phones)
      commit(t.SET_EMAILS, owner.person_emails)
      commit(t.SET_ADDRESSES, owner.person_addresses)
      commit(t.SET_USERNAME, owner.person_username)
      commit(t.SET_USERNAME_TOKEN, owner.person_username_token)
      const hasOwnerPhones = owner.person_phones && owner.person_phones.length > 0
      const hasOwnerAddresses = owner.person_addresses && owner.person_addresses.length > 0
      commit(t.SET_MISSING_CONTACT_INFORMATION, !hasOwnerPhones || !hasOwnerAddresses)
    }
  },

  async createContact ({ rootGetters, dispatch }, person) {
    await http.post(`client/person`, { person })
    if (rootGetters['stagelineSchemaForm/verifyOrderPeople'].length)
      dispatch('stagelineSchemaForm/addVerifyOrderPerson', person, { root: true })
    await dispatch('loadAccount')
  },

  async createDocumentRequestContact({ dispatch }, person) {
    const response = await axiosClient.post('client/person', person)
    await dispatch('loadAccount')
    return response
  },

  async deleteContact ({ dispatch }, { person_id }) {
    await http.delete(`client/person/${person_id}`)

    await dispatch('loadAccount')
  },

  async setCurrentContact ({ commit }, { contact }) {
    commit(t.SET_CURRENT_CONTACT, contact)
  },

  async updatePerson ({ dispatch }, { person }) {
    await http.patch(`client/person/${person.id}`, { person: person })

    await dispatch('loadAccount')
  },

  async updatePersonAddress ({ dispatch }, address) {
    await http.patch(`client/person_addresses/${address.id}`, { address: address })

    await dispatch('loadAccount')
  },

  async updatePersonPhone ({ dispatch }, phone) {
    await http.patch(`client/person_phones/${phone.id}`, { phone: phone })

    await dispatch('loadAccount')
  },

  async updatePersonEmail ({ dispatch }, { email }) {
    await http.patch(`client/person_emails/${email.id}`, { email: email })

    await dispatch('loadAccount')
  },

  async createPersonEmail ({ dispatch }, email) {
    await http.post(`client/person_emails`, { email })

    await dispatch('loadAccount')
  },

  async createPersonPhone ({ dispatch }, phone) {
    await http.post(`client/person_phones`, { phone })

    await dispatch('loadAccount')
  },

  async createPersonAddress ({ dispatch }, address) {
    await http.post(`client/person_addresses`, { address })

    await dispatch('loadAccount')
  },

  async deletePersonPhone ({ dispatch }, { phone_id }) {
    await http.delete(`client/person_phones/${phone_id}`)

    await dispatch('loadAccount')
  },

  async deletePersonEmail ({ dispatch }, { email_id }) {
    await http.delete(`client/person_emails/${email_id}`)

    await dispatch('loadAccount')
  },

  async deletePersonAddress ({ dispatch }, { address_id }) {
    await http.delete(`client/person_addresses/${address_id}`)

    await dispatch('loadAccount')
  },
  async createPersonUsername ({ commit }, { username, personId }) {
    const response = await http.post('client/person-usernames', { username: username, person_id: personId })

    commit(t.SET_USERNAME, response.data.result.username)
    commit(t.SET_USERNAME_TOKEN, response.data.result.mobile_token)
  },

  async checkIfUsernameExists ({ commit }, { username }) {
    const response = await http.get(`client/person-usernames/${username}`)

    commit(t.SET_DOES_USERNAME_EXIST, response.data.result.result)
  },

  async updatePersonUsername ({ commit }, { username, newUsername, personId }) {
    const response = await http.patch(`client/person-usernames/${personId}`, { username: username, new_username: newUsername })

    commit(t.SET_USERNAME, response.data.result.username.username)
  },

  async deletePersonUsername ({ commit }, { username, personId }) {
    await http.delete(`client/person-usernames/${personId}`, { data: { username: username } })

    commit(t.SET_USERNAME, null)
  },
  setIsNotAccountOwner({ commit }, isNotAccountOwner) {
    commit(t.SET_IS_NOT_ACCOUNT_OWNER, isNotAccountOwner)
  },
  setServicesRequiringAttentionCount({ commit }, count) {
    commit(t.SET_SERVICES_REQUIRING_ATTENTION_COUNT, count)
  },
  async setActiveServicesCount({ commit }) {
    const response = await http.get(`client/services`, {
      params: {
        type: SERVICE_TYPES_VALUES,
        status: OPERATING_STATUSES_VALUES,
        limit: 200,
      },
    })
    if (response && response.data && response.data.result) {
      commit(t.SET_ACTIVE_ACCOUNT_SERVICES, response.data.result)
      commit(t.SET_ACTIVE_SERVICES_COUNT, response.data.result.length)
    }
  },
  async loadRedirects({ commit }) {
    const response = await http.get(`client/account/company_requiring_redirect`)
    commit(t.SET_REDIRECT_LOADED, true)

    if (response) {
      // Prioritize global redirects over stageline
      if (response.data.result.path) {
        commit(t.SET_REDIRECT, response.data.result)
      } else {
        commit(t.SET_COMPANY_REQUIRING_STAGELINE_REDIRECT, response.data.result.company_id)
      }
    }
  },

  async updateCompanyRedirect({ commit, getters }, path) {
    const companyId = getters.redirect?.company_id
    if (companyId) {
      await http.post(`client/companies/${companyId}/update_company_redirect`, {
        path: path,
      })

      commit(t.SET_REDIRECT, {})
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_OWNER_ACCOUNT] (state, account) {
    state.account = account
    state.knownBadAddresses = account.person_addresses
  },
  [t.SET_ACTUAL_ACCOUNT_ID] (state, accountID) {
    state.actualAccountID = accountID
  },
  [t.SET_PHONES] (state, phones) {
    state.phones = phones
  },
  [t.SET_ADDRESSES] (state, addresses) {
    state.addresses = addresses
  },
  [t.SET_EMAILS] (state, emails) {
    state.emails = emails
  },
  [t.SET_GENERAL] (state, generalInfo) {
    state.generalInfo = generalInfo
  },
  [t.SET_ALL_PEOPLE] (state, people) {
    state.people = people
  },
  [t.SET_CURRENT_CONTACT] (state, currentContact) {
    state.currentContact = currentContact
  },
  [t.SET_MISSING_CONTACT_INFORMATION] (state, missingContactInformation) {
    state.missingContactInformation = missingContactInformation
  },
  [t.SET_ACCOUNT_DETAILS] (state, account) {
    state.websiteId = account.website_id
    state.type = account.type
  },
  [t.SET_USERNAME] (state, username) {
    state.username = username
  },
  [t.SET_DOES_USERNAME_EXIST] (state, usernameToCheck) {
    state.doesUsernameExit = usernameToCheck
  },
  [t.SET_USERNAME_TOKEN] (state, token) {
    state.usernameToken = token
  },
  [t.SET_IS_NOT_ACCOUNT_OWNER] (state, isNotAccountOwner) {
    state.isNotAccountOwner = isNotAccountOwner
  },
  [t.SET_SERVICES_REQUIRING_ATTENTION_COUNT](state, count) {
    state.servicesRequiringAttentionCount = count
  },
  [t.SET_ACTIVE_SERVICES_COUNT](state, count) {
    state.activeServicesCount = count
  },
  [t.SET_COMPANY_REQUIRING_STAGELINE_REDIRECT](state,companyRequiringStagelineRedirect) {
    state.companyRequiringStagelineRedirect = companyRequiringStagelineRedirect
  },
  [t.SET_STAGELINE_REDIRECTED](state, value) {
    state.stagelineRedirected = value
  },
  [t.SET_REDIRECT_LOADED](state,value) {
    state.redirectLoaded = value
  },
  [t.SET_REDIRECT](state, path) {
    state.redirect = path
  },
  [t.SET_ACTIVE_ACCOUNT_SERVICES](state, services) {
    state.activeAccountServices = services
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
