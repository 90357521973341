import packageJson from '../package.json'
import axiosClient from './http'
import CtFileDrop from '@/components/shared/CtFileDrop'
import CtAutoSave from '@/components/shared/CtAutoSave'
import ContactModal from '@/components/ContactModal'
import dataMap from '@core/data-map-frontend/src/store/modules/dataMap.store'
import dataMap1 from 'data-map-long-term-support/src/store/modules/dataMap.store'
import dataMap2 from 'data-map-stable/src/store/modules/dataMap.store'
import dataMap3 from 'data-map-feature-A/src/store/modules/dataMap.store'
import dataMap4 from 'data-map-feature-B/src/store/modules/dataMap.store'
import dataMap5 from 'data-map-feature-C/src/store/modules/dataMap.store'
import { initDataMapPackage } from '@core/data-map-frontend/src/main.js'
import { initDataMapPackage as initDataMapPackage1 } from 'data-map-long-term-support/src/main.js'
import { initDataMapPackage as initDataMapPackage2 } from 'data-map-stable/src/main.js'
import { initDataMapPackage as initDataMapPackage3 } from 'data-map-feature-A/src/main.js'
import { initDataMapPackage as initDataMapPackage4 } from 'data-map-feature-B/src/main.js'
import { initDataMapPackage as initDataMapPackage5 } from 'data-map-feature-C/src/main.js'

// Let Data Map know if you make breaking changes for any of the following
// Or you could do a search in here and see if it would break anything if you really wanted to :)
// /node_modules/@core/data-map-frontend

// Data Map uses these components:
// <ct-file-drop />
// <ct-auto-save />
// <contact-modal />

// Data Map uses these store interactions:
// account/peopleWithAllRequiredInfo
// checkout/addToCart
// checkout/raServiceActiveOrInCart
// jurisdictions/findByName

export const dataMapPackageModules = { dataMap, dataMap1, dataMap2, dataMap3, dataMap4, dataMap5 }

export function dataMapPackageInit({ Vue, store }) {
  const http = axiosClient
  const components = { CtFileDrop, CtAutoSave, ContactModal }
  initDataMapPackage({ packageJson, Vue, store, http, components })
  initDataMapPackage1({ packageJson, packageNumber: 1, Vue, store, http, components })
  initDataMapPackage2({ packageJson, packageNumber: 2, Vue, store, http, components })
  initDataMapPackage3({ packageJson, packageNumber: 3, Vue, store, http, components })
  initDataMapPackage4({ packageJson, packageNumber: 4, Vue, store, http, components })
  initDataMapPackage5({ packageJson, packageNumber: 5, Vue, store, http, components })
}
