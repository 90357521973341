import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAddressBook,
  faArrowDown,
  faArrowUp,
  faBan,
  faBars,
  faCarAlt,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faCreditCard,
  faEdit,
  faEllipsisV,
  faExclamationCircle,
  faExternalLinkAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faGhost,
  faHandshake,
  faInbox,
  faList,
  faLock,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faPause,
  faPhoneAlt,
  faPlay,
  faPlusSquare,
  faQuestionCircle,
  faShoppingCart,
  faSms,
  faStop,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faVoicemail,
  faSpinner,
  faBuilding,
  faSave,
  faToolbox,
  faHome,
  faNewspaper,
  faKey,
  faTrademark,
  faGlobe,
  faCloud,
  faUserFriends,
  faUniversity,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons'

import {
  faDotCircle,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faAddressBook,
  faArrowDown,
  faArrowUp,
  faBars,
  faBan,
  faCarAlt,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faCreditCard,
  faEdit,
  faEllipsisV,
  faExclamationCircle,
  faExternalLinkAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faGhost,
  faHandshake,
  faInbox,
  faList,
  faLock,
  faUnlock,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faPause,
  faPhoneAlt,
  faPlay,
  faPlusSquare,
  faQuestionCircle,
  faShoppingCart,
  faSms,
  faStop,
  faTimes,
  faTimesCircle,
  faTrash,
  faVoicemail,
  faSpinner,
  faDotCircle,
  faBuilding,
  faSave,
  faToolbox,
  faHome,
  faNewspaper,
  faKey,
  faTrademark,
  faGlobe,
  faCloud,
  faUserFriends,
  faUniversity,
  faBriefcase,
)
